<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <analytics-congratulation />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="data.income"
          icon="UsersIcon"
          :statistic="kFormatter('$' + data.income.total_income)"
          statistic-title="Total Income of UNS"
          :chart-data="data.income.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="data.expenses"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter('$' + data.expenses.expenses_detail)"
          statistic-title="Total Expense of UNS"
          :chart-data="data.expenses.series"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <analytics-avg-sessions :data="data.assessments_total" />
      </b-col>
      <b-col lg="6">
        <analytics-support-tracker :data="data.investor_income" />
      </b-col>
    </b-row>

    <!-- <b-row class="match-height">
      <b-col lg="4">
        <analytics-timeline :data="data.timeline" />
      </b-col>
      <b-col lg="4">
        <analytics-sales-radar-chart :data="data.salesChart" />
      </b-col>
      <b-col lg="4">
        <analytics-app-design :data="data.appDesign" />
      </b-col>
    </b-row> -->

    <!-- <b-row>
      <b-col cols="12">
        <invoice-list />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
// import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
// import AnalyticsAppDesign from './AnalyticsAppDesign.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    // AnalyticsTimeline,
    // AnalyticsSalesRadarChart,
    // AnalyticsAppDesign,
    // InvoiceList,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      data: {
        
      },
    }
  },
  created() {
    // data
    // console.log(this.userData)
    // var id = this.$route.params.userData.id;
    this.$http.get('api/dashboard/investor/'+ this.userData.id)
      .then(response => { 
        this.data = response.data
        console.log(this.data)
      })
  },
  methods: {
    kFormatter,
  },
}
</script>
